@import 'src/styles/colorVariables';

.section-wrapper {
  border-bottom: 1px solid $gray-color2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 9px 0;

  .section {
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-radius: 0px 6px 6px 0px;
    padding-left: 5px;

    span {
      padding-left: 10px;
      padding-right: 12px;
      padding-block: 5px;
    }
    img {
      width: 6px;
      height: 6px;
    }
    .rotate-back {
      transform: rotate(0deg);
      transition: transform 150ms ease;
    }

    &__subsections {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }

    &.blocked {
      color: $neutral-gray-warm-20;
      cursor: default;
      span {
        cursor: default;
      }
    }
  }

  .section:hover:not(.blocked),
  .active:not(.blocked) {
    background-color: $section-active-bg-color;
    color: $on-section-active-text-color;
    width: 100%;
  }

  .blocked-icon-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;
  }
}
