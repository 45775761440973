@import 'src/styles/colorVariables';

.company {
  display: flex;
  align-items: center;

  img {
    margin-right: 0.7rem;
  }
}

.esg-metrics-dash .table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.m-0-2 {
  margin-left: 0.2rem;
}
.dashboard__body-esg {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, min(40vh - 0.25rem, 30rem));
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
}

.dashboard__body .item2 {
  grid-column: span 2;
}

.width-45 {
  width: 45%;
}

.impact-previus-year-comparison {
  grid-area: impact-previus-year-comparison;
}

.impact-facility-org {
  grid-area: impact-facility-org;
}

.impact-intensity-metric {
  grid-area: impact-intensity-metric;
}

.dashboard__body-esg {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min(40vh - 0.25rem, 30rem) 80vh;
  grid-template-areas:
    'impact-previus-year-comparison impact-facility-org'
    'impact-intensity-metric impact-intensity-metric';
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
  > .card,
  > .impact-intensity-metric {
    border: none !important;
  }
}
