@import 'src/styles/colorVariables';

.subsection {
  display: flex;
  padding-left: 5px;
  border-radius: 0px 6px 6px 0px;
  align-items: center;
  position: relative;
  gap: 4px;

  span {
    width: 100%;
    padding-left: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  &.subsection:hover:not(.blocked),
  &.active {
    background-color: $section-active-bg-color;
    color: $on-section-active-text-color;
    width: 100%;
  }

  &.blocked {
    color: $neutral-gray-warm-20;
    cursor: default;
    span {
      cursor: default;
    }
  }

  &.blocked:hover span {
    overflow: visible;
    animation: showFullName 0.5s forwards;
  }
}

.blocked-icon-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
}

@keyframes showFullName {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
