$white: #ffffff;

$primary-color: #2945ff;
$primary-light-color: #38cfd9;
$primary-light-color-10: #38cfd910;

$primary-hover-color: #1c35ed;
$primary-light-hover-color: #53bbcd;

$secondary-color: #6e86f7;
$secondary-light-color: #ced2fa;

$highlight-border-color: #6e86f7;

$icon-bg-color: #eff1fe;
$icon-bg-color-blocked: #d8d1d1;
$on-icon-text-color: #6e86f7;

$section-active-bg-color: #f0f3fe;
$on-section-active-text-color: #6e86f7;

$tag-bg-color: #eff1fe;
$on-tag-text-color: #6e86f7;

$tag2-bg-color: #6e86f7; // Coming soon or new tags
$on-tag2-text-color: #ffffff;

$tag-selected-bg-color: #6e86f7;
$on-tag-selected-text-color: #ffffff;

$review-image-bg-color: #eff1fe;

$scope1-color: #2945ff;
$scope2-color: #38cfd9;
$scope3-color: #ced2fa;

$on-dark-text-color: #ffffff;
$on-light-text-color: #415062;
$on-light-text-color2: #a4a6b3;
$secondary-text-color: #6e86f7;

$placeholder-text-color: #d1d4d8;

$gray-color: #d1d4d8;

$gray-color2: #eaeaea;

$gray-dashboard: #fafafa;

$card-disabled-bg-color: #f3f3f6;
$disabled-color: #a3aab2;
$disabled-table-color: rgba(0, 0, 0, 0.02);

$modal-bg-color: rgba(25, 42, 62, 0.2);

$app-bg-color: #f8f8fb;

$error-color: #f2a09d;
$strong-error-color: #ff2990;
$error-background: #ff299010;

$notification-bg-color: #39393c;
$notification-text-color: #ffffff;

$main-bg-color: #ffffff;
$card-bg-color: #ffffff;

$input-bg-color: #ffffff;
$input-text-color: rgba(25, 42, 62, 0.8);

$primary-button-bg-color: #2945ff;
$primary-button2-bg-color: #38cfd9;
$primary-button-text-color: #ffffff;
$primary-button-border-color: #ffffff;
$primary-button-hover-bg-color: #1c35ed;
$primary-button-hover2-bg-color: #53bbcd;

$secondary-button-bg-color: #ffffff;
$secondary-button-text-color: rgba(25, 42, 62, 0.8);
$secondary-button-border-color: #d1d4d8;

$select-card-bg-color: #ffffff;
$select-card-text-color: rgba(25, 42, 62, 0.8);
$select-card-border-color: #d1d4d8;
$select-card-selected-border-color: #2945ff;
$select-card-disabled-bg-color: rgba(196, 196, 196, 0.1);
$select-card-disabled-border-color: #eaeaea;

$select-options-active-item-bg-color: #2945ff;
$select-options-active-item2-bg-color: #38cfd9;
$select-options-active-item-text-color: #ffffff;
$grey-color-span: #c4c4c4;

$notification-color: #ff2990;

$link-underline-color: #38cfd9e8;
$warning-color: #ff7629;
$warning-bg-color: #fffcea;

$pending-color: $on-tag-text-color;

$gradient-color: linear-gradient(to right, #2945ff, #38cfd9);
$gradient-color-transparency: linear-gradient(to right, rgba(#2945ff, 0.5), rgba(#38cfd9, 0.5));
$gray-light-color: #c4c4c4;
$gray-light-2-color: #eaeaea;
$gray-2-color: #a3aab2;
$neutral-black: #39393c;
$neutral-gray-warm-40: #d8d1d1;
$neutral-gray-warm-60: #eaeaea;
$neutral-gray-warm-80: #fafafa;
$neutral-gray-cold-20: #415062;
$neutral-gray-80: #fafafa;
$neutral-gray-20: #a3aab2;
$neutral-white: #ffffff;
$purple-color: #6e86f7;
$purple-light-color: #f0f3fe;
$purple-40-color: #ced2fa;
$purple-60-color: #e7e7f9;
$black-color: #1a1a1a;
$secondary-purple-20: #6e86f7;
$secondary-purple-40: #ced2fa;
$secondary-purple-80: #f0f3fe;
$tertiary-salmon: #f2a09d;
$ui-colors-green: #38cfd9;
$neutral-gray-warm-20: #c4c4c4;
$tertiary-color: #f8f8fb;
$transport-color: #a0c082;

$input-shadow-hover-color: rgba($purple-40-color, 0.6);
$input-shadow-active-color: rgba($purple-color, 0.4);

/* use this if you need to use scss variables outside of sass (ex: inside a style props) */
:root {
  --disabled-color: #{$disabled-color};
  --warning-color: #{$warning-color};
  --warning-bg-color: #{$warning-bg-color};
  --pending-color: #{$pending-color};
}
