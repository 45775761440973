@import 'src/styles/colorVariables';
@import 'src/styles/fontClasses';

.slide-toggle-wrapper3 {
  display: flex;
  align-items: center;
  width: fit-content;
  // height: 14px;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-right: 4px;
  border-radius: 1rem;
  background-color: $white;
  cursor: pointer;
  .status-switch {
    @extend .body1-font;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
  }

  .blocked {
    cursor: not-allowed;
    color: $neutral-gray-warm-20;
  }

  .selected {
    color: $on-dark-text-color;
  }
}
