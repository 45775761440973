.login-form {
  max-width: 360px;
  width: 75%;
  margin-bottom: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    width: 100%;
  }
  &__footer {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      row-gap: 1rem;
      span {
        text-align: center;
      }
      img {
        width: 32px;
      }
    }
  }
}
