@import 'src/styles/colorVariables';
@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';

// Side Menu
.side-menu {
  width: 75px;
  padding: 40px 20px;
  border-right: 1px solid $gray-color2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  row-gap: 5rem;

  @include break($small) {
    padding-left: 0px;
    padding-top: 0px;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    max-height: 42px;
  }

  img.logo {
    height: auto;
    max-height: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  .bottom-section {
    margin-top: auto;
    margin-bottom: 6rem;
  }

  &__section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;
    &__top,
    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 1.3rem;
    }

    .tooltip {
      width: auto !important;
    }
  }

  &__sub-section-wrapper {
    width: 140px;
    min-width: 140px;

    .tooltip {
      width: auto !important;
    }
  }

  .side-menu-close {
    border-radius: 0.5rem;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    // right: -16px;
    top: 150px;
    background-color: white;
    z-index: 100;

    img {
      width: 11px;
      height: 9px;
      transition: transform 150ms ease;
    }

    img.right {
      transform: rotate(180deg);
    }
  }
}

.section-name {
  display: flex;
  flex-direction: column;
  position: relative;

  .tag-new {
    position: absolute;
    right: 6px;
    top: 4px;
    padding: 0.1rem 0.2rem;
    border-radius: 6px;
  }
}
