.shipment-form {
  .form-wrapper-standard {
    // overflow-y: auto;
    padding-right: 1rem;
    .input__text-select {
      .input {
        flex-basis: 155px !important;
      }
    }
    .routes {
      width: 100%;
    }
  }
  .form-line-full {
    overflow-y: auto;
    max-height: 40vh;
  }

  .transport-section {
    .form-wrapper-standard {
      padding-right: 0;
    }

    .transport {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 8px;

      .icon-wrapper {
        cursor: pointer;
        margin-top: auto;
      }
    }

    .line-divider {
      width: 100%;
      height: 1px;
      background-color: #e5e5e5;
    }
  }

  .routes-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px 8px;
    margin-bottom: 16px;

    .line-divider {
      width: 100%;
      height: 1px;
      background-color: #e5e5e5;
    }

    .transport-container {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .single-input-transport {
      width: 100%;
      .transport {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
        .icon-wrapper {
          cursor: pointer;
        }
      }
    }

    .single-transport-container {
      width: 49%;
    }

    .column {
      flex-basis: calc(50% - 8px);
      flex-grow: 1;
      h3 {
        margin-top: 0px !important;
        margin-bottom: 8px;
      }
      .origin-destination {
        .add-route {
          margin-top: 9px;
          cursor: pointer;
          display: inline-flex;
          justify-content: space-between;
          column-gap: 6px;
          p {
            margin: 0 !important;
          }
        }
      }
      .transport {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
        .icon-wrapper {
          cursor: pointer;
        }
      }
    }
  }
  .routes-wrapper:last-child {
    margin-bottom: 0 !important;
  }
  .error-text {
    margin-top: 8px;
  }
}
