// Google Font Inter
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$weights: 'Thin', 'ExtraLight', 'Light', 'Regular', 'Medium', 'SemiBold', 'Bold', 'ExtraBold',
  'Black';

@for $i from 1 through 9 {
  @font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-#{nth($weights, $i)}.ttf') format('truetype');
    font-weight: #{100 * $i};
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .font-#{to-lower-case(nth($weights, $i))},
  .weight-#{100 * $i} {
    font-weight: #{100 * $i};
  }
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter Semibold';
  src: url('./fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('./fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('./fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Font Variables
$font-regular: 'Inter', serif;
$font-semibold: 'Inter Semibold', serif;
$font-bold: 'Inter Bold', serif;