@import 'src/styles/colorVariables';
@import 'src/styles/fontVariables';
.dashboard-card {
  // Evolution
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
    max-height: calc(100% - 36px);
  }
  .dates-legend {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    .tag-wrapper {
      height: 14px;
    }

    .dates-current-year {
      .tag-wrapper {
        background: $purple-60-color !important;
        color: $neutral-gray-cold-20 !important;
      }
    }

    .dates-previous-year {
      .tag-wrapper {
        background: $neutral-gray-warm-60 !important;
        color: $neutral-gray-20 !important;
      }
    }

    .tag-wrapper span {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      line-height: 0;
    }
  }

  .chart-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    width: 100%;
    canvas {
      width: 30px;
      margin-top: 0.5rem;
      height: 100% !important;
    }
  }

  .chart-wrapper,
  .bar-chart-wrapper {
    max-height: 92%;

    * {
      font-family: $font-regular !important;
    }
  }

  .evolution-comparison-tag {
    display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    height: fit-content;
    column-gap: 0.5rem;
    row-gap: 0.5rem;

    .tag-elem {
      padding-right: 0.5rem;
    }

    .tag-wrapper {
      background: transparent !important;
    }
  }

  .dashboard__body-holdings {
    .button-wrapper-holdings {
      margin-left: 0.33em;
    }
    .button-wrapper-companies {
      margin-left: 0.33em;
    }
  }
}
