@import 'src/styles/colorVariables';

.dashboard__body-control-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  gap: 1rem;

  > .card,
  > .card-generic-wrapper {
    border: none !important;
  }
}
.framework-progress {
  .framework-progress__logo {
    width: 54px;
    height: 54px;

    img {
      width: 100%;
      height: auto;
    }
  }
}
