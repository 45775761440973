@import 'src/styles/colorVariables.scss';
.description-box {
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  // align-items: center;
  height: fit-content;
  ul {
    padding-inline-start: 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    li {
      margin-bottom: 0.5rem;
      a {
        color: $primary-color;
        cursor: pointer;
      }
      code {
        display: inline-block;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
  ul > li:has(ul) {
    list-style-type: none;
    padding-left: 0; /* Adjust if needed */
  }
}
