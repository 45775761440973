@import 'colorClasses';
@import 'fontClasses';
@import 'fontVariables';
@import 'colorVariables';
@import 'forms';
@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';
@import 'src/styles/utils';
@import 'src/styles/stories';
@import 'src/styles/animations';
@import 'src/styles/nuvo';
@import 'src/styles/cssReset';
@import 'src/styles/reactFlow';


#hubspot-messages-iframe-container {
  z-index: 999 !important;
  visibility: hidden;
}


body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: $font-regular;
  color: $on-light-text-color;
  background-color: $app-bg-color;
  letter-spacing: -0.03em !important;
  line-height: 120% !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin-left: 5px;
}

*::-webkit-scrollbar-button {
  background: #ccc;
  height: 0;
}

*::-webkit-scrollbar-track-piece {
  background: $gray-dashboard;
}

*::-webkit-scrollbar-thumb {
  background: $gray-color2;
  border-radius: 5px;
}

*:focus {
  outline: none;
}

.flex {
  display: flex;
}

.link-style {
  border-bottom: 1px solid transparent;
  border-image: linear-gradient($primary-color, $primary-light-color);
  border-image-slice: 1;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.rotate180 {
  transform: rotate(180deg);
  transition: transform 150ms ease;
}

.rotate-back {
  transform: rotate(0deg);
  transition: transform 150ms ease;
}

.mt-0 {
  margin-top: 0px !important;
}

.mr-16 {
  margin-right: 16px;
}

.disabled-opacity {
  opacity: 0.5;
  margin-top: 40px;
  margin-bottom: 20px;
  pointer-events: none;
}

.round {
  border-radius: 50%;
}

.pointer {
  cursor: pointer;
}

.card {
  border-radius: 5px;
  border: 1px solid $gray-color2;
  background-color: $card-bg-color;
  box-sizing: border-box;
}

.showAnimation {
  visibility: visible;
  opacity: 1;
  transition: visibility 1.5s, opacity 1.5s, ease-in-out 1.5s;
}

.hideAnimation {
  visibility: hidden;
  opacity: 0;
  // transition: visibility 1.5s, opacity 1.5s, ease-in-out 1.5s;
}

.pac-container {
  border-radius: 5px;
  border: 1px solid $gray-color;
  box-shadow: none;
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 5px;
  margin-left: -1px;
  background: $input-bg-color;

  .pac-item {
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    padding: 0 !important;

    .pac-icon {
      display: none;
    }

    span {
      font-family: $font-regular !important;
      color: $input-text-color !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      letter-spacing: -0.03em !important;
      line-height: 120% !important;
    }

    .pac-item-query {
      padding: 8px !important;
    }
  }

  .pac-item:hover {
    background: linear-gradient(to right,
        $select-options-active-item-bg-color,
        $select-options-active-item2-bg-color );

    span {
      color: $select-options-active-item-text-color !important;
      box-sizing: border-box !important;
    }
  }
}

.pac-container::after {
  display: none;
}

.page-header {
  height: 8.5%;

  h3 {
    margin-top: 0px;
  }
}

.delete-modal {
  height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0px;
  }

  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.hide {
  opacity: 0; // hide it visually
  z-index: -1; // avoid unintended clicks
  position: absolute; // don't affect other elements positioning
  height: 0 !important;
  width: 0 !important;
}

.small-device-flex {
  @include break($small) {
    display: flex;
    flex-direction: column;
  }
}

.medium-grid-none {
  @include break($xlarge) {
    grid-template-rows: none !important;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.clickable:hover {
  cursor: pointer;
}

input {
  border: none;
}

strong {
  font-family: $font-semibold;
}

.link {
  @extend .highlight-text-color, .pointer;

  text-decoration: underline !important;
  text-decoration-color: $link-underline-color !important;
}

.gradient\:marker {
  list-style: none;

  &::before {
    content: '';
    position: relative;
    left: -10px;
    border-radius: 100%;
    background: -webkit-linear-gradient($primary-color, $primary-light-color);

    display: inline-block;
    width: 5px;
    height: 5px;
  }
}

.button-as-card-border {
  border-color: $gray-color2 !important;

  &:hover {
    border-color: $primary-color !important;
  }
}
