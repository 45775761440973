@import 'src/styles/colorVariables';

.carousel-line {
  height: 0.375rem;
  margin-top: 0.8rem;
  border-radius: 5px;
  background-color: $neutral-gray-warm-40;
}

.carousel-line.blocked {
  background-color: $neutral-gray-warm-60;
}

.carousel-line.selected {
  background-color: $secondary-purple-20;
}

.carousel-line.error {
  background-color: $strong-error-color;
}
