@import 'src/styles/colorVariables';

.big.selected {
  font-weight: 600;
  color: $secondary-purple-20;
}

.big.blocked {
  color: $neutral-gray-warm-20;
}

.big.error {
  color: $strong-error-color;
}
