@import 'src/styles/colorVariables';
@import 'src/styles/clipPaths';
@import 'src/styles/fontClasses';
.label {
  @extend .font-body-b2-r;
  display: grid;
  grid-template-columns: 1rem auto;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  margin-left: 6px;
  margin-block: 6px;
  position: relative;
  z-index: 0;

  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    cursor: pointer;
    background: white;

    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;

    display: grid;
    place-content: center;
    background-clip: padding-box;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -2px;
      background: $gradient-color;
    }

    &.square {
      border-radius: 3px;

      &::before {
        border-radius: inherit;
      }
    }
  
    &.circle {
      border-radius: 50%;

      &::before {
        border-radius: 50%;
      }
    }
  }

  &[aria-disabled='false'] {
    &:hover {
      input[type='checkbox'] {
        box-shadow: inset 0 0 0 6px $input-shadow-hover-color;

        &::after {
          content: '';
          border-radius: 50%;
          background-color: $input-shadow-hover-color;
          position: absolute;
          width: 24px;
          height: 24px;
          top: -6px;
          left: -6px;
          z-index: -2;
        }
      }
    }

    &:active {
      input[type='checkbox'] {
        box-shadow: inset 0 0 0 6px $input-shadow-active-color;

        &::after {
          content: '';
          border-radius: 50%;
          background-color: $input-shadow-active-color;
          position: absolute;
          width: 24px;
          height: 24px;
          top: -6px;
          left: -6px;
          z-index: -2;
        }
      }
    }
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
    color: $gray-light-color;

    input[type='checkbox'] {
      cursor: not-allowed;
      &::before,
      &::after {
        background: $gray-light-color;
      }
    }
  }
}
