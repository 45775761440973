@import 'src/styles/colorVariables';

.step-carousel {
  font-weight: 400;
  color: $neutral-gray-cold-20;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  .label-div {
    width: fit-content;
  }

  &:not(.selected):not(.blocked):hover {
    color: $secondary-purple-20;
  }

  &:not(.selected):not(.blocked):hover > .text-tag,
  &:not(.selected):not(.blocked):hover > .carousel-line {
    background-color: $secondary-purple-40;
  }

  &:not(.blocked):hover {
    cursor: pointer;
  }
}
